export const META_DESCRIPTIONS = {
  home: 'Welcome to the Secure World of Diment Dollar, a Diamond Backed Stablecoin',
  dimentDollar:
    'Diment Dollar: Stablecoin Bringing Trust, Transparency and Stability to the Cryptocurrency Ecosystem',
  howItWorks:
    'Diment Dollar operates in an ecosystem created by the combination of diamonds and blockchain',
  transparency:
    'Diment Dollar: A Pre-Audited, Pre-Backed Secure Stablecoin Built on Transparency',
  fees: 'Fee to be given through the system during the realisation of your payment transactions',
  aboutUs:
    "Diment Dollar: The World's First Pre-Audited and Pre-Backed Diamond Stablecoin Equipped with a Secure System",
  contactUs:
    'Diment Cares About Your Feedback; Contact Us for Questions, Suggestions or Cooperation Proposals',
  legalTerms:
    'Users are hereby informed about data protection at Diment Dollar and details about their personal data',
  imprint: 'Information Required for a Legally Transparent and Secure Website',
  mediaAssets:
    'Detailed Information on Brand and Text Guidelines and Elements Included in Corporate Identity',
  socialMedia:
    'All Contact Information Regarding Our Social Media Accounts for Our Users to Easily Access',
  helpCenter:
    'A Solution Centre Equipped with the Competencies to Help You with Any Problem',
  networks:
    "Partnering with an Ever-Growing Network of Many of the Industry's Leading and Successful Names",
  quant:
    'Diment Quant leverages a multi-disciplinary approach combining research, technology, implementation, and risk management to innovate and secure its position in the global diamond market',
  faq: 'Here you can find frequently asked questions. We help you to find the answer',
  verify:
    'Ensure the authenticity of your Diment interactions by using the Diment Verify tool to confirm official contacts and domains',
  error404:
    'Page Not Found. The content you’re searching for doesn’t exist or has been moved. Please return to the homepage to continue exploring Diment’s offerings.',
};

export const META_TITLES = {
  home: 'Diment Dollar - The Reinvention of Stablecoin.',
  dimentDollar: 'Cryptocurrency Ecosystem - Diment Dollar',
  howItWorks: 'Diamonds and Blockchain - Diment Dollar',
  transparency: 'Transparency, Audited, Backed and Secure - Diment Dollar',
  fees: 'Fees - Diment Dollar',
  aboutUs: 'About This Amazing Team - Diment Dollar',
  contactUs: 'Contact Us - Diment Dollar',
  legalTerms: 'Legal Terms - Diment Dollar',
  imprint: 'Imprint - Diment Dollar',
  mediaAssets: 'Media Assets - Diment Dollar',
  socialMedia: 'Social Media - Diment Dollar',
  helpCenter: 'A Solution Center - Diment Dollar',
  networks: 'Partnerships - Diment Dollar',
  quant: 'Quant - Diment Dollar',
  faq: 'FAQs - Diment Dollar',
  verify: 'Verify - Diment Dollar',
  error404: '404 Error - Diment Dollar',
};
