import {
  FormControl,
  FormLabel,
  Input,
  Textarea,
  FormErrorMessage,
} from '@chakra-ui/react';

type FormFieldProps = {
  label: string;
  placeholder: string;
  register: any;
  error?: string;
  type?: string;
  isTextArea?: boolean;
};

const FormField = ({
  label,
  placeholder,
  register,
  error,
  type = 'text',
  isTextArea = false,
}: FormFieldProps) => (
  <FormControl isInvalid={!!error}>
    <FormLabel>{label}</FormLabel>
    {isTextArea ? (
      <Textarea placeholder={placeholder} {...register} />
    ) : (
      <Input type={type} placeholder={placeholder} {...register} />
    )}
    <FormErrorMessage>{error}</FormErrorMessage>
  </FormControl>
);

export default FormField;
