import BaseButton from '@/components/BaseButton';
import { Box, Flex, SimpleGrid, Stack, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'next-i18next';
import { FaXTwitter } from 'react-icons/fa6';
import { outerLinks } from '@/utils/constants/outer-links';
import Link from 'next/link';
import { colors } from '@/styles/theme/colors';
import { H2 } from '@/components/Headings';
import { TextDefault, TextSmall } from '@/components/Texts';
import { FiArrowUpRight } from 'react-icons/fi';
import Image from 'next/image';
import SupportModal from './SupportModal';

const CallToAction = () => {
  const { t } = useTranslation('main');
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Stack
      bg={colors.gradients.darkBlue}
      spacing={6}
      w='full'
      align={'center'}
      justify={'center'}
      px={{ base: 8, md: 16, xl: 8 }}
      py={{ base: 16, xl: 24 }}
    >
      <Stack w='full' maxW={'8xl'} align={'center'}>
        <SimpleGrid
          justifyItems={{ base: 'center', md: 'inherit' }}
          w={'full'}
          templateColumns={{ base: '1fr', md: '4fr 5fr' }}
          gap={{ base: 20, md: 0 }}
        >
          <Stack
            color={'white'}
            align={'center'}
            justify={'center'}
            w={'full'}
            maxW={'lg'}
            spacing={12}
            textAlign={{ base: 'center', lg: 'left' }}
          >
            <Stack w={'full'} align={'center'} spacing={8}>
              <H2>{t('common.join-community.header')}</H2>
              <Stack
                display={{ base: 'flex', md: 'none' }}
                justify={'center'}
                position={'relative'}
                w={'full'}
                maxW={{ base: '3xs', sm: 'xs' }}
              >
                <Image
                  src={`/images/diment-coin.webp`}
                  alt='Join Dimmet Community'
                  width={800}
                  height={445}
                  priority
                />
              </Stack>
              <TextSmall
                w={'full'}
                maxW={{ base: 270, sm: 'full' }}
                fontSize={{ base: '15px', md: '16px' }}
              >
                {t('common.join-community.desc')}
              </TextSmall>
            </Stack>
            <Flex
              w={'full'}
              wrap={'wrap'}
              justify={{ base: 'center', lg: 'left' }}
              gap={4}
            >
              <Link href={outerLinks.socials.twitter} target={'_blank'}>
                <BaseButton
                  minW={{ base: 36, sm: 48 }}
                  py={6}
                  leftIcon={<FaXTwitter />}
                  bg={colors.diment.lightBlue}
                  color={colors.diment.darkBlue}
                >
                  <TextDefault fontWeight={800}>
                    {t('common.join-community.join-now')}
                  </TextDefault>
                </BaseButton>
              </Link>
              <BaseButton
                minW={{ base: 36, sm: 48 }}
                py={6}
                rightIcon={<FiArrowUpRight />}
                fontSize={'xl'}
                color={'white'}
                variant={'outline'}
                onClick={onOpen}
              >
                <TextDefault>{t('common.join-community.support')}</TextDefault>
              </BaseButton>
              <SupportModal isOpen={isOpen} onClose={onClose} />
            </Flex>
          </Stack>
          <Stack
            display={{ base: 'none', md: 'flex' }}
            justify={'center'}
            align={'center'}
            position={'relative'}
          >
            <Box>
              <Image
                src={`/images/diment-coin.webp`}
                alt='Join Dimmet Community'
                width={800}
                height={445}
                priority
              />
            </Box>
          </Stack>
        </SimpleGrid>
      </Stack>
    </Stack>
  );
};

export default CallToAction;
