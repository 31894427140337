import { Box } from '@chakra-ui/react';
import { useInView, motion, useAnimation } from 'framer-motion';
import React, { useEffect, useRef } from 'react';

interface Props {
  children: JSX.Element;
  animate: boolean;
}
const Reveal = ({ children, animate }: Props) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start('visible');
    }
  }, [isInView, mainControls]);

  return (
    <Box position={'relative'} ref={ref}>
      {animate ? (
        <motion.div
          variants={{
            hidden: { opacity: 0, y: 75 },
            visible: { opacity: 1, y: 0 },
          }}
          initial={'hidden'}
          animate={mainControls}
          transition={{ duration: 0.5, delay: 0.25 }}
        >
          {children}
        </motion.div>
      ) : (
        children
      )}
    </Box>
  );
};

export default Reveal;
