import axios from 'axios';

const api = axios.create({
  baseURL: 'https://api.diment.io',
});

export type JoinWaitlistBody = { email: string };

export type CheckRefCodeBody = { code: string };

export type SendContactBody = {
  subject?: string;
  email?: string;
  name?: string;
  message?: string;
  phone?: string;
  title?: string;
};

export type ReferralRequestBody = {
  investmentType: string;
  fullName: string;
  emailAddress: string;
};

export type SupportRequestBody = {
  name: string;
  email: string;
  subject: string;
  message: string;
  issue: string;
};

export const sendSupportRequest = (body: SupportRequestBody) =>
  api.post('/postman/support-request', body);

export const joinWaitlist = (body: JoinWaitlistBody) =>
  api.post('/newsletter/register', body);

export const checkRefCode = (body: CheckRefCodeBody) =>
  api.post('/referral/familiar/check/', body);

export const sendContact = (body: SendContactBody) =>
  api.post('/postman/contact', body);

export const getAccountVerification = (name: string) =>
  api.post(`/settings/social-account-verification`, { name });
