import {
  checkRefCode,
  CheckRefCodeBody,
  getAccountVerification,
  joinWaitlist,
  JoinWaitlistBody,
  ReferralRequestBody,
  sendContact,
  SendContactBody,
  sendSupportRequest,
  SupportRequestBody,
} from '@/services/apis.service';
import { useToast } from '@chakra-ui/react';
import { useMutation } from 'react-query';

export const useJoinWaitlistMutation = () => {
  return useMutation({
    async mutationFn(values: { body: JoinWaitlistBody }) {
      return joinWaitlist(values.body).then((res) => res.data);
    },
  });
};

export const useCheckRefcodeMutation = () => {
  return useMutation({
    async mutationFn(values: { body: CheckRefCodeBody }) {
      return checkRefCode(values.body).then((res) => res.data);
    },
  });
};

export const useSendContactMutation = () => {
  const toast = useToast();
  return useMutation({
    mutationFn: (body: SendContactBody) => {
      return sendContact(body).then((res) => res.data);
    },
    onError() {
      toast({
        title: 'Cannot send message.',
        description: 'Please try again later.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    },
    onSuccess() {
      toast({
        title: 'Successful',
        description: 'Your message was sent successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    },
  });
};

export const useAccountVerificationMutation = () => {
  return useMutation({
    async mutationFn(values: { name: string }) {
      return getAccountVerification(values.name).then((res) => res.data);
    },
  });
};

export const useReferralRequestMutation = () => {
  const toast = useToast();
  return useMutation({
    async mutationFn(values: { body: ReferralRequestBody }) {
      const mailBody: SendContactBody = {
        title: 'Referral Request',
        email: values.body.emailAddress,
        message: `${
          values.body.fullName
        } | ${values.body.investmentType.toUpperCase()}`,
      };

      return sendContact(mailBody).then((res) => res.data);
    },
    onError() {
      toast({
        title: 'Cannot send.',
        description: 'Please try again later.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    },
    onSuccess() {
      toast({
        title: 'Your request sent successfully.',
        description: 'Our team will contact you soon. Thank you!',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    },
  });
};

export const useSupportRequestMutation = () => {
  return useMutation({
    mutationFn: async (body: SupportRequestBody) => {
      const res = await sendSupportRequest(body);
      return res.data;
    },
  });
};
