import { NextPage } from 'next';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import * as yup from 'yup';
import React, { useEffect } from 'react';
import BaseButton from '@/components/BaseButton';
import { colors } from '@/styles/theme/colors';
import { useSupportRequestMutation } from '@/queries/apis.query';
import { SupportRequestBody } from '@/services/apis.service';
import FormField from '@/components/FormField';

type SupportFormData = Omit<SupportRequestBody, 'issue'> & { issue?: string };

type SupportModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const SupportModal: NextPage<SupportModalProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation('main');
  const toast = useToast();

  const formSchema = yup.object().shape({
    name: yup.string().required(t('yup-messages.required')),
    email: yup
      .string()
      .email(t('yup-messages.email'))
      .required(t('yup-messages.required')),
    subject: yup.string().required(t('yup-messages.required')),
    message: yup.string().required(t('yup-messages.required')),
  });

  const { register, handleSubmit, formState, reset } = useForm<SupportFormData>(
    {
      defaultValues: {
        name: '',
        email: '',
        subject: '',
        message: '',
      },
      resolver: yupResolver(formSchema),
    },
  );

  const { errors } = formState;
  const mutation = useSupportRequestMutation();

  useEffect(() => {
    if (!isOpen) reset();
  }, [isOpen, reset]);

  const onSubmit = (body: SupportFormData) => {
    const requestBody: SupportRequestBody = {
      ...body,
      issue: 'dimentio-homepage',
    };

    mutation.mutate(requestBody, {
      onSuccess: () => {
        toast({
          title: t('help.support.modal.success'),
          description: t('help.support.modal.success-desc'),
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        reset();
        onClose();
      },
      onError: () => {
        toast({
          title: t('help.support.modal.error'),
          description: t('help.support.modal.error-message'),
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      },
    });
  };

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <ModalOverlay />
      <ModalContent color={colors.diment.darkBlue}>
        <ModalHeader>{t('help.support.modal.title')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={4}>
              <FormField
                label={t('help.support.modal.name-label')}
                placeholder={t('help.support.modal.name-placeholder')}
                register={register('name')}
                error={errors.name?.message}
              />
              <FormField
                label={t('help.support.modal.email-label')}
                placeholder={t('help.support.modal.email-placeholder')}
                register={register('email')}
                error={errors.email?.message}
                type='email'
              />
              <FormField
                label={t('help.support.modal.subject-label')}
                placeholder={t('help.support.modal.subject-placeholder')}
                register={register('subject')}
                error={errors.subject?.message}
              />
              <FormField
                label={t('help.support.modal.describe-label')}
                placeholder={t('help.support.modal.message-placeholder')}
                register={register('message')}
                error={errors.message?.message}
                isTextArea
              />
              <BaseButton
                bg={colors.diment.darkBlue}
                isLoading={mutation.isLoading}
                type='submit'
              >
                {t('help.support.modal.send')}
              </BaseButton>
            </Stack>
          </form>
        </ModalBody>
        <ModalFooter />
      </ModalContent>
    </Modal>
  );
};

export default SupportModal;
